// Sticky Footer with Bootstrap 5.0
html,
body {
	@extend .h-100;
}

// IE11 bugfix
.d-flex, .col {
	-ms-flex: 1 0 auto !important;
}

body {
	@extend .d-flex;
	@extend .flex-column;

	> footer {
		@extend .mt-auto;
	}
}

.documentDescription {
	@extend .mb-4;
}

#main-container a:hover {
	text-decoration: underline;
}
#portal-header,
#portal-footer {
	a {
		color: $primary;
	}
}

#portal-header {
	margin-top: 0;
	margin-bottom: 0;

	#portal-logo {
		width: auto;
		margin-top: 0;

		img {
			height: 70px;
		}
	}
}

body #portal-header {
	display:flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	margin-right: auto;
}

body:not(.site-subsite) #portal-header {
	@include media-breakpoint-down(lg) {
		text-align: center;
		flex-direction: column-reverse;
		align-items: center;
	}
}

body.site-subsite #portal-header {
	@include media-breakpoint-down(md) {
		text-align: center;
		flex-direction: column-reverse;
		align-items: start;

		#portal-siteactions {
			margin-top:1.5rem;
			margin-left:0;
		}
	}
}

#portal-search a {
	color: black !important;
	padding: 0.5rem 0 0.5rem 6rem;
	display: inline-block;
}

#content-header {
	padding-top: $spacer * 1.25;
	padding-bottom: $spacer * 2;

	@include media-breakpoint-down(lg) {
		> .container {
			display: flex;
		}
	}
}
#content-header,
#mainnavigation-wrapper {
	background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	&,
	a {
		color: $primary;
	}
}

body {
	@include media-breakpoint-down(md) {
		font-size: 1rem;
	}
}

// restructure column-two to sit outside of content grid
body.col-two.col-content {
	#main-container {
		position: relative;
		min-height:450px;
		margin-bottom:0;
	}
	#portal-column-content {
		width: 100% !important;
	}
	@include media-breakpoint-up(md) {
		// move right column outside
		#portal-column-two {
			flex: unset;
			position: absolute;
			left: 100%;
			width: 33%;
			padding-left: 3.5rem;
			padding-right: 0;

			// sqare contact images
			.rounded-corner {
				width: 150px;
				height: 150px;
			}
		}
	}
}

#voki-headerlinks {
	background-color: rgb(246, 246, 246);
	font-size: $font-size-base * 0.54;
	padding: 0.5rem 0;

	svg {
		height: 12px;
		width: auto;
	}
	a {
		color: $body-color;
		margin-right: 1rem;
	}

	.text-end a {
		margin-right: 0;
		margin-left: 1rem;
	}
}

#portal-siteactions {
	@extend .list-inline;
	@extend .d-flex;
	@extend .align-items-end;

	margin: 0;
	order: -3;
	align-self: end;

	@include media-breakpoint-down(lg) {
		margin-left:4rem;
	}

	> li {
		@extend .list-inline-item;

		> a {
			@extend .btn;
			@extend .btn-link;

			display: table-cell;
			vertical-align: middle;
			height: 45px;
			min-width: 70px;
			line-height: 0.9;
			padding: 0 0.5rem;
			color: white;
			text-transform: uppercase;
			font-weight: $font-weight-bolder;
		}
	}

	#siteaction-donate > a {
		background-color: map-get(map-get($section-colors, "text-orange"), "headings");
	}

	#siteaction-pate {
		@include media-breakpoint-down(md) {
			display: none;
		}

		> a {
			background-color: $primary;
		}
	}

	#siteaction-shop > a {
		background-color: map-get(map-get($section-colors, "dunkelblau"), "headings");
		padding: 0.25rem 1rem;
	}

	#siteaction-kinderdorf_portal > a {
		background-color: transparent;
		width: 290px;
		height: 70px;
		@include media-breakpoint-down(lg) {
			width:200px;
			height:50px;
		}
	}
	#siteaction-kindervor_portal {
		> a {
			background-color: transparent;
			padding: 0;
			img {
				height:100%;
			}
		}
		@include media-breakpoint-up(lg) {
			padding-left: 3.4rem;
			> a {
				width: 118px;
				height: 70px;
			}
		}

	}

	#siteaction-perspektiven > a {
		background-color: $primary;
		color: map-get(map-get($section-colors, "dunkelblau"), "headings");
		padding: 0.25rem 1rem;
		width: 16rem;
	}
}

body:not(.site-subsite) #portal-siteactions {
	@include media-breakpoint-down(lg) {
		margin-top: 1.5rem;
		margin-left: 1.4rem;
	}
}

#portal-globalnav-wrapper {
	#portal-globalnav {
		@extend .list-unstyled;

		margin-left: -1rem;
		margin-right: -1rem;
		margin-bottom: 1.5rem;

		input,
		label {
			@extend .visually-hidden;
		}

		li {
			&.current,
			&.inPath {
				> a {
					&,
					&:hover,
					&:focus {
						color: map-get(map-get($section-colors, "text-orange"), "headings");
						background-color: transparent;
					}
				}
			}

			@each $name, $colors in $section-colors {
				&.#{$name} > a,
				&.#{$name} a:hover,
				&.#{$name} li.current a {
					color: map-get($colors, "headings");
				}
			}
		}

		> li {
			> a {
				font-size: $font-size-lg;
				font-weight: $font-weight-bold;
				padding: var(--bs-navbar-nav-link-padding-x) var(--bs-navbar-nav-link-padding-y);
			}

			a {
				background-color: transparent;
				color: $voki-navbar-main;

				&:hover,
				&:focus {
					background-color: transparent;
					color: map-get(map-get($section-colors, "text-orange"), "headings");
				}
			}

			> .dropdown {
				@extend .position-absolute;
				@extend .start-0;
				@extend .w-100;
				@extend .shadow-sm;

				display: none;
				z-index: 1001;
				padding-top: 2rem;
				padding-bottom: 1rem;

				&.active {
					display: block;
				}

				> ul {
					@extend .d-flex;
					@extend .flex-wrap;
					@extend .list-inline;
					@extend .container;

					padding-left: 0;

					.nav-link {
						padding: 0 1rem 0.25rem 1rem;
					}

					> li {
						@extend .list-inline-item;
						@extend .col-3;

						margin-bottom: 1rem;
						margin-right: 0 !important;

						> .nav-link {
							font-weight: $font-weight-bold;
						}
					}
				}

				ul {
					@extend .list-unstyled;
				}
			}
		}
	}
}
.navbar-toggler {
	border-color: transparent;
	color: $voki-darkgrey;
}

body.globalnav-expanded {
	#mainnavigation,
	.dropdown {
		background-color: rgb(246, 246, 246);
	}
}

#portal-column-content {
	background-color: white;
}

#plone-breadcrumb {
	background-color: transparent;
	font-size: 0.85rem;
	margin-top: 1rem;

	.breadcrumb {
		padding: 0;

		a,
		.breadcrumb-item.active {
			color: $body-color;
		}

		// safari fix
		.breadcrumb-item {
			white-space: nowrap;
		}
	}
}

#content header h1 {
	margin-top: 4rem;
}

#portal-footer-wrapper {
	background-color: $plone-footer-bg;
	color: $plone-footer-fg;
	padding: 0;
	text-align: start;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	table {
		&,
		a {
			color: white;
		}
	}

	#sponsors-wrapper {
		color: $body-color;
		background-color: white;
		padding: 1rem 0;
	}

	#below-footer-wrapper {
		background-color: $below-footer-bg;
		padding: 1rem 0;
	}

	#icon-links img {
		max-height: 120px;
	}
}

.documentDescription {
	font-weight: $font-weight-bold;
}

#related-images {
	a {
		@extend .mb-4;
		position: relative;
		display: block;

		.plone-icon {
			position: absolute;
			right:35px;
			top:15px;
			color:$body-color;
		}
		img {
			@extend .img-fluid;
			@extend .w-100;
		}
	}
}

.image-responsive {
	@extend .img-fluid;
	@extend .w-100;
}

#section-leadimage {
	margin-right: calc(var(--bs-gutter-x) / -2);
	margin-left: calc(var(--bs-gutter-x) / -2);

	&,
	figure {
		margin-bottom: 0;
	}
	img {
		@extend .w-100;
	}
}

.outstand {
	@extend .display-3;
	color: map-get(map-get($section-colors, "text-orange"), "headings");
}

// helper classes
.mh-350 {
	max-height: 350px;
}
.mh-300 {
	max-height: 300px;
}
.mh-250 {
	max-height: 250px;
}
.minh-250 {
	min-height: 250px;
}
.minw-100 {
	min-width: 100%;

}
.inverted {
	color:white !important;
	background-color: $primary;
}

.section-color {
	color: $primary;
}
.btn.section-color {
	color: white;
	background-color:$primary;

	&:hover {
		color: black;
	}
}

.lilita {
	font-weight: $font-weight-bolder;
}

form.voki-form {
	legend {
		font-size:$font-size-sm;
		margin-top: $spacer * 1.5;
		padding-bottom: $spacer * .5;
		border-bottom:solid 1px $primary;
	}
}


// SHOP
#cart_viewlet #cart_viewlet_summary a {
	margin-top: 0.75rem;
}
#portlet-cart #cart_items {
	max-height:550px;
	overflow-y:scroll;
	overflow-x:hidden;
}
.card-img-top .product_listing_image {
	@extend .img-fluid;
	@extend .w-100;
}
.buyable.viewlet {
	@extend .my-3;
}
img.product_image {
	@extend .float-none;
}
img.cart_item_preview_image {
	float:none;
	margin:0;
}
.cart_summary_buttons {
	flex-direction: row-reverse;
	a.cart_back {
		display:none;
	}
}

// hide shipping label
.shipping_info .shipping_label,
.portletContent tr.shipping,
tr.shipping .summary_ammount,
tr.shipping .sumary_currency,
#form-checkout.mode_edit h2:nth-of-type(4),
#form-checkout.mode_display h2:nth-of-type(5),
#field-checkout-shipping_selection-shipping {
	display: none !important;
}
.shipping_info {
	max-width: 100%;
}
.collectionSearch .searchContent,
.portlet.collectionSearch .searchContent,
.collectionFilter .filterContent,
.portlet.collectionFilter .filterContent,
.collectionSortOn .filterContent,
.portlet.collectionSortOn .filterContent {
	padding: 0.5rem 0;
}

body.template-product_view {
	#content {
		@extend .row;

		#content-core {
			@extend .col-md-6;
			@extend .order-md-2;
		}

		#viewlet-below-content-body {
			@extend .col-md-6;
			@extend .order-md-1;

			#related-images {
				@extend .row;

				.image-item {
					@extend .col-4;

					&.first {
						@extend .col-12;
					}

					img {
						padding: 0.25rem;
					}
				}
			}
		}

		> footer {
			@extend .order-md-3;
		}
	}
}

.popover.add-to-cart-message {
	@include media-breakpoint-down(sm) {
		left:1rem !important;
	}
}

// IE11 fix
#mmenu.visually-hidden {
	display:none;
}

#edit-zone {
	font-size: 0.9rem;

	.dropdown-menu {
		--bs-dropdown-font-size: 0.9rem;
	}
}
