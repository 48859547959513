@font-face{
    font-family:"TommySoft";
    src:url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_regular-webfont.woff2") format("woff2"),
    url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_regular-webfont.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
@font-face{
    font-family:"TommySoft";
    src:url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_medium-webfont.woff2") format("woff2"),
    url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_medium-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face{
    font-family:"TommySoft";
    src:url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_bold-webfont.woff2") format("woff2"),
    url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_bold-webfont.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face{
    font-family:"TommySoft";
    src:url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_extrabold-webfont.woff2") format("woff2"),
    url("/++theme++kinderdorf-theme/fonts/made_tommy_soft_extrabold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

// override for barceloneta
$roboto-base-path: "/++theme++barceloneta/roboto/";

