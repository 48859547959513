// default font
$font-family-sans-serif: "TommySoft", Arial, sans-serif;
$link-decoration: none;

$body-color: rgb(87,87,86);

$font-size-base: 1.125rem;
$font-size-lg: $font-size-base * 1.15;

$h1-font-size: $font-size-base * 1.85;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.4;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

$font-weight-base: $font-weight-light;
$headings-font-weight: $font-weight-bolder;
$display-font-weight: $font-weight-bolder;

$line-height-base: 1.293;
$grid-gutter-width: 2.5rem;

$breadcrumb-bg: transparent;

// only 4 grid tiers
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px
);

$container-max-widths: (
  sm: 420px,
  md: 720px,
  lg: 960px
);

