/* MOSAIC */
body.mosaic-grid {
	#portal-column-content {
		background-color: transparent;
		.mosaic-grid-row {
			background-color: white;
			&.mosaic-grid-row-fluid {
				background-color: transparent;
			}
		}
	}
}

.mosaic-grid-row > .col {
	@include media-breakpoint-down(md) {
		flex:0 0 auto;
		width:100%;
	}
}

.mosaic-grid-row-padding-top {
	@include media-breakpoint-up(md) {
		padding-top: 3rem !important;
	}
	> .col {
		@include media-breakpoint-down(md) {
			padding-top:1rem;
		}
	}
}

.mosaic-grid-row-padding-bottom {
	@include media-breakpoint-up(md) {
		padding-bottom: 3rem !important;
	}
	> .col {
		@include media-breakpoint-down(md) {
			padding-bottom:1rem;
		}
	}
}

.mosaic-tile-container {
	@extend .container;
	padding: 0 0.75rem;
	margin:0 auto !important;
}

.mosaic-tile-card .mosaic-tile-content {
	@extend .card;
	border: none;

	> p {
		> a {
			font-weight: $font-weight-bolder;
		}
		> img,
		> a img {
			@extend .card-img-top;
			margin-bottom: -1rem;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@extend .card-title;
		@extend .text-center;

		padding: 0.5rem 0;
		background-color: $primary;

		&,
		a {
			color: #ffffff;
		}
	}
}

.mosaic-tile-standout {
	@include media-breakpoint-up(md) {
		margin-left: -20%;
	}
}

.mosaic-tile-topslider {
	max-width: 1540px;
	margin: 0 auto !important;

	.carousel img {
		max-height: 540px;
	}
}

.mosaic-tile-donate {
	position:absolute !important;
	z-index: 1000 !important;
	top: 0;
	right: 0;
	overflow:hidden;

	&.mosaic-selected-tile {
		overflow:visible;
		.mosaic-tile-content {
			margin-top:0;
		}
	}

	button {
		font-size: $font-size-base;
	}

	.mosaic-tile-content {
		@extend .rounded-circle;

		display: flex;
		align-items:center;
		justify-content: center;
		text-align:center;
		width: 14rem;
		height: 14rem;
		font-size: 2.6rem;
		line-height: 1;
		text-transform: uppercase;
		background-color: $primary-80;
		margin-top: -3rem;

		@include media-breakpoint-down(md) {
			width: 10rem;
			height: 10rem;
			font-size: 2rem;
			margin-top: -2rem;
		}

		&,
		a {
			color: white !important;
			font-weight: $font-weight-bolder;
		}

		> :first-child {
			margin: 1rem 0 0 0;
			@include media-breakpoint-down(md) {
				margin-top: 0.5rem;
			}
		}
	}
}

.mosaic-tile .carousel {
	margin-left: calc(var(--bs-gutter-x) / -2);
	margin-right: calc(var(--bs-gutter-x) / -2);

	img {
		min-height: 300px;
		min-width: 100%;
		object-fit: cover;
	}
	.carousel-caption {
		text-shadow: 1px 1px black;
		left: 4rem;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@include media-breakpoint-down(md) {
				font-size: 2rem;
			}

			&,
			a {
				color: rgb(255, 255, 255);
			}
		}
	}
}

.mosaic-tile-button-fullwidth .mosaic-tile-content a {
	@extend .btn;
	@extend .btn-primary;
	@extend .w-100;

	border: none;
	color: white;
}

// regenerate fluid rows with new grid
body.template-layout,
body.mosaic-enabled {
	&.no-portlet-columns {
		.outer-wrapper > .container:not(#content-header) {
            // outline: 1px blue solid;
            max-width: unset;
            padding-left: unset;
            padding-right: unset;

            > .row {
                margin: 0;

                // --bs-gutter-x: 0;
                #portal-column-content {
                    padding: 0;
                }
            }

            .mosaic-grid-row {
                width: 100%;
                padding-right: 0;
                padding-left: 0;
                margin-right: auto;
                margin-left: auto;

                @each $breakpoint, $container-max-width in $container-max-widths {
                    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                        max-width: $container-max-width;

                        &.mosaic-grid-row-fluid-background {
                            padding-left: calc((100% - $container-max-width) * 0.5);
                            padding-right: calc((100% - $container-max-width) * 0.5);
                        }
                    }
                }

                &.mosaic-grid-row-fluid {
                    max-width: unset;
                    padding-left: unset;
                    padding-right: unset;
                    margin-left: 0;
                    margin-right: 0;
                }
                &.mosaic-grid-row-fluid-background {
                    max-width: unset;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
	}
}

// section rows

@each $name, $colors in $section-colors {
	.mosaic-grid-row-#{$name} {
		background-color: map-get($colors, "bg") !important;
		@if map-has-key($colors, "color") {
			color: map-get($colors, "color");
		}

		.section-color {
			color: map-get($colors, "headings");
		}

		.btn.section-color {
			background-color: map-get($colors, "headings");
			border-color: map-get($colors, "headings");
			color: map-get($colors, "bg");

			&:hover {
				background-color: map-get($colors, "bg");
				border-color: map-get($colors, "bg");
				color: black;
			}
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		a {
			&,
			a {
				color: map-get($colors, "headings");
			}
		}

		.mosaic-tile-card .mosaic-tile-content {
			background-color: transparent;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				background-color: map-get($colors, "headings");
			}
		}

		.mosaic-tile-button-fullwidth .mosaic-tile-content a {
			background-color: map-get($colors, "headings");
		}
	}
}

.mosaic-tile-content {
	h1, h2, h3 {
		margin-bottom:1rem;
	}
}
