$primary: rgb(252,190,0);
$primary-80: rgba(252,190,0,0.8);

// Plone
$plone-link-color: $primary;
$plone-link-color-on-dark: #16a1e3!default; //plone blue

//if you need different contrast
$plone-link-color-on-grey: #086ca3!default; //valid wcag 2.0
$plone-link-hover-color: darken($plone-link-color, 15%) !default;

//*// SCAFFOLDING
// $plone-body-bg: #fafafa!default; //almost-white
$plone-footer-bg: rgb(0,55,112);
$below-footer-bg: rgb(0,42,76);
$plone-footer-fg: rgb(255,255,255);

$voki-darkgrey: rgb(87, 87, 87);
$voki-navbar-main: $voki-darkgrey;

$section-colors: (
	"familienimpulse": (
		"headings": rgb(0, 149, 194),
		"bg": rgb(226, 237, 241)
	),
	"netzwerk-familie": (
		"headings": rgb(228, 43, 51),
		"bg": rgb(255, 235, 228)
	),
	"familiendienst": (
		"headings": rgb(235, 97, 40),
		"bg": rgb(254, 240, 231)
	),
	"auffanggruppe": (
		"headings": rgb(0, 107, 170),
		"bg": rgb(233, 237, 248)
	),
	"paedakoop": (
		"headings": rgb(160, 178, 81),
		"bg": rgb(248, 247, 236)
	),
	"pflegekinderhilfe": (
		"headings": rgb(165, 124, 174),
		"bg": rgb(246, 240, 248)
	),
	"kinderdorf-kronhalde": (
		"headings": rgb(0, 107, 170),
		"bg": rgb(233, 237, 248)
	),
	"ehemaligenbetreuung": (
		"headings": rgb(0, 107, 170),
		"bg": rgb(233, 237, 248)
	),
	"dunkelblau": (
		"headings": rgb(0, 42 , 76),
		"bg": transparent
		),
	"text-gelb-bg-dunkelblau": (
		"headings": $primary,
		"color": white,
		"bg": rgb(0, 42 , 76)
	),
	"text-orange": (
		"headings": rgb(235, 97, 40),
		"bg": rgb(255, 248, 235)
	),
	"text-gelb": (
		"headings": $primary,
		"bg": rgb(255, 248, 235)
	),
	"text-gelb-invertiert": (
		"headings": white,
		"bg": $primary
	)
);
